import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Label, Button, Alert } from 'reactstrap';
import { FaFileDownload } from "react-icons/fa";
import { saveAs } from 'file-saver';

//polyfill ie 11 support 
import 'url-search-params-polyfill';
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();//ie 11 support for axios/fetch
const axios = require('axios');

interface MyProps { location: any }
class Request extends React.Component<MyProps, any> {
    _isMounted = false;
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this._isMounted = true;
        const params = new URLSearchParams(window.location.search);
        const confirmationNumber = params.get('cn');

        if (confirmationNumber !== undefined && confirmationNumber != null
            && this.props.location.state !== undefined && this.props.location.state.isAuthenticated) {
                this.getRequest(confirmationNumber);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getRequest(confirmationNumber: string) {
        axios.get('/api/request/getRequest?confirmationNumber=' + confirmationNumber)
            .then((response: any) => {
                if (response.data && this._isMounted) {
                    this.setState({
                        confirmationNumber: response.data.confirmationNumber,
                        requestId: response.data.requestId,
                        requestTypeId: response.data.requestType,
                        requestType: response.data.displayRequestType,
                        verificationDate: response.data.verificationDate
                    });
                }
            })
            .catch((error: any) => {
                console.log(error);
                this.setState({
                    apiSuccess: false,
                    apiMessage: "There was an issue locating your request. If you continue to have problems please contact us at (800) 435-4978."
                });
            });   
    }

    handlerDownload(confirmationNumber: string, requestId: number) {
        axios.get('/api/request/getrequestdownload?confirmationNumber=' + confirmationNumber + '&requestId=' + requestId)
            .then((response: any) => {
                var blob = new Blob([response.data], { type: "text/plain;charset=utf-8" });
                saveAs(blob, "data-request.txt");               
            })
            .catch((error: any) => {
                console.log(error);
                this.setState({
                    apiSuccess: false,
                    apiMessage: "There was an issue downloading your data. If you continue to have problems please contact us at (800) 435-4978."
                });
            });
    }

    handlerConfirm(confirmationNumber: string, requestId: number) {
        axios.post('/api/request/setconfirmdate', { 'confirmationNumber': confirmationNumber, 'requestId': requestId })
            .then((response: any) => {
                if (response.data) {
                    this.setState({
                        apiSuccess: true,
                        apiMessage: "You have successfully confirmed your request.",
                        verificationDate: response.data
                    });
                }              
            })
            .catch((error: any) => {
                console.log(error);
                this.setState({
                    apiSuccess: false,
                    apiMessage: "There was an issue confirming your request. If you continue to have problems please contact us at (800) 435-4978."
                });
            });
    }

    render() {
        let action;

        if (this.props.location.state === undefined || !this.props.location.state.isAuthenticated) {
            const params = new URLSearchParams(window.location.search);
            const confirmationNumber = params.get('cn');

            return <Redirect to={{ pathname: "/verify", search: "?cn=" + confirmationNumber }} />
        }

        if (this.state.requestTypeId == 1) {
            action = <FaFileDownload style={{ color: '#51cf66', cursor: 'pointer' }} size={32} onClick={() => this.handlerDownload(this.state.confirmationNumber, this.state.requestId)} />
        } else if ((this.state.requestTypeId == 2 || this.state.requestTypeId == 3) && this.state.verificationDate === null) {
            action = <Button color="primary" size="lg" active aria-label="click to confirm data request." onClick={() => this.handlerConfirm(this.state.confirmationNumber, this.state.requestId)}>Confirm</Button>
        } else if (this.state.verificationDate !== null) {
            action = <span style={{ fontWeight: 600 }}>You confirmed your request on:&nbsp;<span>{this.state.verificationDate}</span></span>
        }

        return (
            <div>
                {this.state.apiSuccess !== undefined
                        ? <Alert color={this.state.apiSuccess ? 'success' : 'danger'}>{this.state.apiMessage}</Alert>
                        : null}
                <Label>{this.state.requestType}</Label><br />
                {action}
            </div>
        );
    }
}

export default (Request)