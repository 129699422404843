import * as React from 'react';

class VerifyRequestInformation extends React.Component {
    render() {
        return (
            <div>
                <h1>Personal Information Access Form</h1>
                <p>
                    To review, change, or remove the personal information you requested on SelectBlinds.com, please  log into our site with the same password and/or information used previously to confirm your identity in order to access your records.
                </p>            
                <p>
                    If you have any questions about this form or submitting your request, please contact us toll-free at (800) 435-4978.
                </p>
            </div>
        )
    }
}
export default (VerifyRequestInformation)