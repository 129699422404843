import * as React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import PhoneInput from 'react-phone-input-2'

type MyProps = { handlerOnChange: any, handlerPhoneNumber: any, firstname: string, lastname: string, email: string, phone: string };
class LoginGuest extends React.Component<MyProps, any> {
    constructor(props : any) {
        super(props);
    }

    render() {
        return (
            <div>
                <Label>Please provide the following information so we can look you up.</Label>
                <FormGroup>
                    <Input
                        type="text"
                        name="firstname"
                        id="firstname"
                        placeholder="First Name"
                        aria-label="First name that has been used to create accounts/orders"
                        required
                        value={this.props.firstname}
                        onChange={this.props.handlerOnChange} />
                </FormGroup>
                <FormGroup>
                    <Input
                        type="text"
                        name="lastname"
                        id="lastname"
                        placeholder="Last Name"
                        aria-label="Last Name that has been used to create accounts/orders"
                        required
                        value={this.props.lastname}
                        onChange={this.props.handlerOnChange} />
                </FormGroup>
                <FormGroup>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        aria-label="Email Address that has been used to create accounts/orders"
                        required
                        value={this.props.email}
                        onChange={this.props.handlerOnChange} />
                </FormGroup>
                <FormGroup>
                    <PhoneInput
                        country='us'
                        disableCountryCode={true}
                        defaultMask="(...) ... ...."
                        alwaysDefaultMask={true}
                        inputClass="form-control"
                        specialLabel=""
                        aria-label="Phone Number that has been used to create accounts/orders."
                        value={this.props.phone}
                        onChange={this.props.handlerPhoneNumber}
                        inputProps={{
                            name: "phone",
                            id: "phone",
                            placeholder: "Enter Phone Number",
                            required: true,
                            autoFocus: false,
                        }}
                    />
                   
                </FormGroup>
            </div>
        )
    }
}

export default (LoginGuest)

