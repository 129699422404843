import * as React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

type MyProps = { handlerOnChange: any, email: string };
class Unsubscribe extends React.Component<MyProps, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <Label>Please provide the email address you wish to unsubcribe to. We will send a link to unsubscribe.</Label>

                <FormGroup>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        aria-label="Email address used to login to selectblinds.com."
                        required
                        value={this.props.email}
                        onChange={this.props.handlerOnChange} />
                </FormGroup>
              
            </div>
        )
    }
}

export default (Unsubscribe)