import * as React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Button, Spinner, Alert } from 'reactstrap';

import LoginSelectBlinds from './LoginSelectBlinds';
import LoginGuest from './LoginGuest';
import VerifyRequestInformation from './VerifyRequestInformation';
import FormReCaptcha from './FormReCaptcha';
import { Redirect, Link } from 'react-router-dom';

//polyfill ie 11 support 
import 'url-search-params-polyfill';
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
const axios = require('axios');

class Verify extends React.Component<{}, any> {
    _isMounted = false;
    constructor(props: any) {
        super(props);
        this.state = {
            isSubmit: false,
            isAuthenticated: false
        };
        this.handlerPhoneNumber = this.handlerPhoneNumber.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        const params = new URLSearchParams(window.location.search);
        const confirmationNumber = params.get('cn');

        if (confirmationNumber != undefined && confirmationNumber != null) {
            this.verifyConfirmation(confirmationNumber);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    verifyConfirmation(confirmationNumber: string) {
        axios.get('/api/auth/getauthenticationtype?confirmationNumber=' + confirmationNumber)
            .then((response: any) => {
                if (response.data && this._isMounted) {
                    this.setState({
                        isLogin: response.data.isSelectBlindsUser,
                        confirmationNumber: response.data.confirmationNumber,
                        isValid: response.data.isValid
                    })
                }
            })
            .catch((error: any) => {
                console.log(error);
                this.setState({
                    apiSuccess: false,
                    apiMessage: "There was an issue locating your request. If you continue to have problems please contact us at (800) 435-4978."
                });
            });
    }

    handlerRecaptcha = (recaptchaToken: string) => {
        this.setState({ recaptchaToken: recaptchaToken });
    }

    handlerPhoneNumber(phoneNumber: string) {
        this.setState({ phone: phoneNumber })
    }

    handlerSubmitState() {
        this.setState({ isSubmit: !this.state.isSubmit })
    }

    onChange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        this.handlerSubmitState();

        axios.post('/api/auth/authenticate', this.state)
            .then((response: any) => {
                this.setState({ isAuthenticated: true });
            })
            .catch((error: any) => {
                console.log(error);
                this.setState({
                    apiSuccess: false,
                    apiMessage: "There was an issue with your login. Please check your credentials or contact us at (800) 435-4978."
                });
            })
            .then(() => {
                this.handlerSubmitState();
            });
    }

    render() {
        if (this.state.isAuthenticated) {
            return <Redirect to={{
                pathname: "/request", search: "?cn=" + this.state.confirmationNumber
                , state: { isAuthenticated: this.state.isAuthenticated }
            }} />
        }

        return (
            <div>
                {this.state.isValid === undefined
                ? <div><Spinner size="sm" color="primary" /> <span>Loading...</span></div>
                    : this.state.isValid
                        ?
                            <Form id="verifyForm" onSubmit={this.onSubmit}>
                                <VerifyRequestInformation />
                                {this.state.apiSuccess !== undefined
                                    ? <Alert color={this.state.apiSuccess ? 'success' : 'danger'}>{this.state.apiMessage}</Alert>
                                    : null}

                                {this.state.isLogin
                                    ? <LoginSelectBlinds
                                        handlerOnChange={this.onChange}
                                        username={this.state.username}
                                        password={this.state.password}
                                    />
                                    : <LoginGuest
                                        handlerOnChange={this.onChange}
                                        handlerPhoneNumber={this.handlerPhoneNumber}
                                        firstname={this.state.firstname}
                                        lastname={this.state.lastname}
                                        email={this.state.email}
                                        phone={this.state.phone}
                                    />
                                }

                                <FormReCaptcha handlerRecaptcha={this.handlerRecaptcha} />

                                {this.state.isSubmit || this.state.recaptchaToken === undefined
                                    ? <Spinner size="sm" color="primary" />
                                    : <Button color="primary" size="lg" active aria-label="Verify your credentials">Confirm</Button>}
                            </Form>
                        : <div>
                            <Alert color='danger'>There was an issue locating your request. If you continue to have problems please contact us at (800) 435-4978.</Alert>
                            <Link to="/">Put in new request</Link>
                          </div>
                    
                    }
            </div>        
        );
    }
}

export default (Verify)

