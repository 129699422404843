import * as React from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

type MyProps = { handlerOnChange: any, username: string, password: string };
class LoginSelectBlinds extends React.Component<MyProps, any> {
    constructor(props: any) {
        super(props);   
    }

    render() {
        return (         
            <div>             
                <Label>Login With SelectBlinds.com</Label>

                <FormGroup>
                    <Input
                        type="text"
                        name="username"
                        id="userName"
                        placeholder="Email Address"
                        aria-label="Email address used to login to selectblinds.com."
                        required
                        value={this.props.username}
                        onChange={this.props.handlerOnChange} />
                </FormGroup>
                <FormGroup>
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        aria-label="Password used to login to selectblinds.com."
                        required
                        value={this.props.password}
                        onChange={this.props.handlerOnChange} />
                </FormGroup>
            </div>
        )
    }
}

export default (LoginSelectBlinds)

