import * as React from 'react';
import { connect } from 'react-redux';

import { ReCaptcha } from 'react-recaptcha-v3'
import { loadReCaptcha } from 'react-recaptcha-v3'


var ES6Promise = require("es6-promise");
ES6Promise.polyfill();//ie 11 support for axios/fetch
const axios = require('axios');

const site_key_promise = getRecaptchaKey();

async function getRecaptchaKey() {
    var key = "";
    await axios.get('/api/auth/getrecaptchasitekey')
        .then((response: any) => {
            key = response.data;
        })
        .catch((error: any) => {
            console.log(error);
        });

    return key;
}

type MyProps = { handlerRecaptcha: any };
class FormReCaptcha extends React.Component<MyProps, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            recaptchaSiteKey: ""
        }
    }

    componentDidMount() {
        site_key_promise.then(result => {
            this.setState({
                recaptchaSiteKey: result
            });
            loadReCaptcha(result);
        });
    }

    render() {
        return (
            <ReCaptcha
                sitekey={this.state.recaptchaSiteKey}
                action='homepage'
                verifyCallback={this.props.handlerRecaptcha}
            />
        );
    }

}

export default (FormReCaptcha)