import * as React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Button, Spinner, Alert } from 'reactstrap';
import SubmitRequestInformation from './SubmitRequestInformation';
import LoginSelectBlinds from './LoginSelectBlinds';
import LoginGuest from './LoginGuest';
import Unsubscribe from './Unsubscribe';
import FormReCaptcha from './FormReCaptcha';

var ES6Promise = require("es6-promise");
ES6Promise.polyfill();//ie 11 support for axios/fetch
const axios = require('axios');


class Home extends React.Component<{}, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLogin: true,
            isSubmit: false,
        };
        this.handlerLoginType = this.handlerLoginType.bind(this);
        this.handlerPhoneNumber = this.handlerPhoneNumber.bind(this);
    }

    handlerRecaptcha = (recaptchaToken: string) => {
        this.setState({ recaptchaToken: recaptchaToken });
    }


    handlerLoginType() {
        this.setState({ isLogin: !this.state.isLogin })
    }

    handlerPhoneNumber(phoneNumber: string) {
        this.setState({ phone: phoneNumber })
    }

    handlerSubmitState() {
        this.setState({ isSubmit: !this.state.isSubmit })
    }

    handlerClearForm() {
        this.setState({
            dataRequestType: "",
            comment: "",
            username: "",
            password: "",
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
        })
    }

    onChange = (e: any) => {
        this.setState({[e.target.name]: e.target.value });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        this.handlerSubmitState();

        axios.post('/api/request/submitrequest', this.state)
            .then((response: any) => {
                console.log(response);
                this.setState({
                    apiSuccess: true,
                    apiMessage: 'Request submitted successfully.'
                });
                this.handlerClearForm();
            })
            .catch((error: any) => {
                console.log(error);
                this.setState({
                    apiSuccess: false,
                    apiMessage: "There was an issue submitting the request. Please check your credentials or contact us at (800) 435-4978."
                });
            })
            .then(() => {
                this.handlerSubmitState();
            })
    }

    render() {
        return (
            <Form id="requestForm" onSubmit={this.onSubmit}>
                <SubmitRequestInformation />
                {this.state.apiSuccess !== undefined
                    ? <Alert color={this.state.apiSuccess ? 'success' : 'danger'}>{this.state.apiMessage}</Alert>
                    : null }
                <FormGroup>
                    <Input
                        type="select"
                        name="dataRequestType"
                        id="dataRequestType"
                        aria-label="Select data request type."
                        required
                        value={this.state.dataRequestType}
                        onChange={this.onChange}>
                            <option value="">Select</option>
                            <option value="1">Get data we store</option>
                            <option value="2">Delete data we store</option>
                            <option value="3">Update data we store</option>
                            <option value="4">Unsubscribe from emails</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Input
                        type="textarea"
                        name="comment"
                        id="comment"
                        placeholder="Comments"
                        aria-label="Enter a comment about the request."
                        required
                        value={this.state.comment}
                        onChange={this.onChange} />
                </FormGroup>

                {this.state.dataRequestType == 4
                    ? <div>
                            <Unsubscribe
                                handlerOnChange={this.onChange}
                                email={this.state.email}
                            />
                        </div>

                    :  this.state.isLogin
                   
                        ? <div>
                                <LoginSelectBlinds
                                    handlerOnChange={this.onChange}
                                    username={this.state.username}
                                    password={this.state.password}
                                />

                                <FormGroup>
                                    Don't have a SelectBlinds.com login?<a href={void 0} aria-label="Click if you don't have a selectblinds account." onClick={this.handlerLoginType}> Click here.</a>
                                </FormGroup>
                            </div>
                        : <div>
                            <LoginGuest
                                handlerOnChange={this.onChange}
                                handlerPhoneNumber={this.handlerPhoneNumber}
                                firstname={this.state.firstname}
                                lastname={this.state.lastname}
                                email={this.state.email}
                                phone={this.state.phone}
                            />

                            <FormGroup>
                                <a href={void 0} aria-label="Click if you have a selectblinds account" onClick={this.handlerLoginType}>Login with SelectBlinds.com username and password.</a>
                            </FormGroup>
                        </div>
                }

                <FormReCaptcha handlerRecaptcha={this.handlerRecaptcha} />

                {this.state.isSubmit || this.state.recaptchaToken === undefined
                    ? <Spinner size="sm" color="primary" />
                    : <Button color="primary" size="lg" active aria-label="Submit request.">Submit</Button>}              
            </Form>
        );
    }
}

export default (Home)

